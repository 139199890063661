import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import './MainHome.css';
import {Provider} from 'react-redux';
import IntroPage from "./IntroPage";


function App() {

    /**
     * pc , mobile check
     */
        // 모바일 여부
    let isMobile = false;

    // PC 환경
    const filter = "win16|win32|win64|mac|macintel";
    const varUA = navigator.userAgent.toLowerCase();
    if (navigator.platform) {
        isMobile = filter.indexOf(navigator.platform.toLowerCase()) < 0;
        if(isMobile) {
            console.log('mobile here');
            console.log(filter);
            let param = document.location.href.split('/'); // 추천인코드
            console.log('param',param);
            window.location.href = 'https://partnerf.smartbc.kr?code=' +param[3];
        }else{
            console.log('web here');
            console.log(navigator.platform);
        }
    }

    return (
            <Router>
                <Switch>
                    {/*메인*/}
                    <Route exact path={`/:JoinCode`} component={IntroPage}/>
                </Switch>
            </Router>
    );
}

export default App;
